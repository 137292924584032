import Styled from 'styled-components';

import { breakpoints, themes } from '../../styles/variables';
import { H1, Paragraph } from '../../styles/commonStyles';

export const Container = Styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ fullHeight }) => fullHeight && 'min-height: 100vh'};
  padding: ${({ first }) => (first ? '15rem' : '2rem')} 2rem 2rem;
  background-color: ${({ theme }) => themes[theme].background};

  @media ${breakpoints.tabletLandscape}, ${
  breakpoints.tabletPortrait
} AND (orientation: landscape) {
    padding: ${({ first }) => (first ? '15rem' : '5rem')} 5rem 5rem;
    flex-direction: ${({ mirrored }) => (mirrored ? 'row-reverse' : 'row')};
  }
`;

export const Picture = Styled.img`
  height: 80%;
  width: 80%;

  @media ${breakpoints.tabletPortrait} {
    height: 70%;
    max-width: 70%;
  }

  @media ${breakpoints.desktop} {
    height: 65%;
    max-width: 65%;
  }

  @media ${breakpoints.desktopL} {
    height: 5%;
    max-width: 50%;
  }
`;

export const RightColumn = Styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 100%;

  @media ${breakpoints.tabletLandscape} {
    align-items: flex-start;
  }

  @media ${breakpoints.desktop} {
    height: 50%;
    max-width: 100%;
  }
`;

export const LeftColumn = Styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  height: 100%;
  width: 100%;

  @media ${breakpoints.desktop} {
    height: 50%;
    max-width: 50%;
  }
`;

export const HeadingContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.tabletPortrait} {
    transform: none;
  }
`;

export const ButtonWrapper = Styled.div`
  display: flex;
  margin: 2rem 0;

  @media ${breakpoints.desktop} {
    display: none;
  }
`;

export const StyledH1 = Styled(H1)`
  text-align: center;

  @media ${breakpoints.tabletLandscape} {
    text-align: left;
  }
`;

export const StyledParagraph = Styled(Paragraph)`
  text-align: center;

  @media ${breakpoints.tabletLandscape} {
    text-align: left;
  }
`;

export default {
  Container,
  HeadingContainer,
  ButtonWrapper,
  Picture,
};
